<template>
  <div class="container">
    <div :class="css">
      <input type="file" name="file" class="d-none" />
      <div class="file-upload-in" @click="upload">{{ prompt }}<div class="upload-progress"><div class="bar" style="width: 0%;"></div></div></div>
    </div>
    <div class="text-danger"><p v-for="(error,idx) in errors" :key="idx">{{ error }}</p></div>
  </div>
</template>
<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

import Vue from 'vue'
import pubsub_mixin from '@/shared/pubsub_mixin'

export default {
  mixins: [pubsub_mixin],
  props: {
    appendResultTo: {
      type: String,
      default: '.file-upload-in'
    },
    attr: {
      type: Object,
      required: true
    },
    css: {
      type: String,
      default: ''
    },
    formData: {},
    method: {
      type: String
    },
    imageOnly: {
      type: Boolean,
      default: false
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    prompt: {
      type: String,
      default: 'Attach file'
    },
    processResult: {
      type: Function,
      default: (result, key) => {
        return result
      }
    },
    showResult: {},
    subscriptionPath: {
      type: String
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      loading: true,
      activeProgress: null
    }
  },
  methods: {
    subscribeUploads() {
      if (!this.subscribed && this.subscriptionPath) {
        this.fayeSubscribe(this.subscriptionPath, 'uploading', (channel, msg) => {
          if (msg.upload == 'started') {
            this.$emit('update:loading', true)
          }
          if (msg.upload == 'completed') {
            this.$emit('update:loading', false)
          }
        })
        this.subscribed = true
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      if (this.activeProgress)
        this.activeProgress.hide();
    },
    upload(e) {
      $(e.target).prev().click()
    },
  },
  mounted() {
    $(document).on('drop dragover', function (e) {
      e.preventDefault();
    });
    this.reset();
    var self = this
    var $el = $(this.$el)
    var options = {
      dataType: 'json',
      method: 'POST',
      url: this.url,
      singleFileUploads: true,
      paramName: this.attr.name,
      sequentialUploads: true,
      headers: {
        'X-CSRF-Token': $("meta[name='csrf-token']").attr("content")
      },
      formData: self.formData,
      add(e, data) {
        self.activeProgress = data.fileInputClone.parent().find('.upload-progress');
        self.activeProgress.show();
        data.submit();
      },
      done(e, data) {
        var field = data.fileInputClone.parent();
        self.activeProgress.hide();
        var key = self.attr.key;
        const keyValue = data.result[key];
        if (typeof(keyValue)  == 'string') {
          $el.find('.js-link .js-file-' + keyValue).remove();
        }
        var urls = self.processResult(data.result, key)
        if (typeof(self.showResult) == 'function') {
          self.showResult(urls, $el)
          return
        }

        // so it is possible to disable showing the result without specifying a callback
        if (urls.url && self.showResult) {
          var link_to_file = $('<a target="_blank" class="js-link js-file-'+ keyValue +'"></a>');
          if (self.imageOnly) {
            link_to_file.append('<img class="thumbnail" />');
            link_to_file.find('img').prop('src', urls.thumb.url);
          } else {
            link_to_file.append('View')
          }
          link_to_file.prop('href', urls.url);
          $el.find(self.appendResultTo).after(link_to_file);
        }
      },
      progressall(e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        self.activeProgress.find('.bar').css('width', progress + '%');
      }
    };

    if (this.method)
      options.method = this.method

    var uploader = $el.fileupload(options);

    uploader.on("fileuploadadd", (evt, data) => {
    });

    uploader.on("fileuploadstart", (evt, data) => {
    });

    uploader.on("fileuploaddone", (evt, data) => {
    });

    uploader.on("fileuploadfail", (evt, data) => {
      if (data.response().jqXHR.responseJSON) {
        this.errors = data.response().jqXHR.responseJSON.errors
      }
      this.reset()
    });

    uploader.on("fileuploadchange", (evt, data) => {
    });
/*
    uploader.on("fileuploaddragover", function(evt, data) {
      evt.preventDefault(); // Prevents the default dragover action of the File Upload widget
    });
*/
    this.subscribeUploads()
  },
  watch: {
    subscriptionPath(newValue) {
      this.subscribeUploads()
    },
  }
}
</script>
